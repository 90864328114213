<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title
          >Angsuran Pinjaman
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="$router.go(-1)">Kembali</v-btn>
        </v-card-title>
        <v-alert small dense type="info" v-if="item.status == 'BELUM LUNAS'">
          {{ item.status }}
        </v-alert>
        <v-alert small dense type="error" v-else>
          {{ item.status }}
        </v-alert>
        <v-simple-table dense style="width: 100%">
          <tbody>
            <tr>
              <td><b>Kode Anggota</b> : {{ item.kode_anggota }}</td>
              <td><b>Nama</b> : {{ item.nama }}</td>
              <td>
                <b>Wilayah</b> :
                <span v-if="item.wilayah_luar === '1'" style="color: red">{{ item.wilayah }}</span>
                <span v-else>{{ item.wilayah }}</span>
              </td>
            </tr>
            <tr>
              <td><b>Tgl Lahir</b> : {{ formatDate(item.tgl_lahir) }}</td>
              <td><b>NIK</b> : {{ item.nik }}</td>
              <td><b>Jenis Upah</b> : {{ item.jenis_upah }}</td>
            </tr>
            <tr>
              <td><b>Tgl Masuk</b> : {{ formatDate(item.tgl_masuk) }}</td>
              <td><b>Jabatan</b> : {{ item.jabatan }}</td>
              <td><b>Jumlah Upah : </b>{{ new Intl.NumberFormat(['id']).format(item.jumlah_upah) }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table dense style="width: 100%; margin-top: 20px">
          <tbody>
            <tr>
              <td><b>Tgl Pinjam</b> : {{ formatDate(item.tgl_pinjaman) }}</td>
              <td><b>Program</b> : {{ item.nama_jenis }}</td>
              <td><b>Bunga (%)</b> : {{ item.persen_bunga }}</td>
            </tr>
            <tr>
              <td>
                <b>Besar Pinjam</b> :
                {{ new Intl.NumberFormat(['id']).format(item.besar_pinjam) }}
              </td>
              <td>
                <b>Jangka Waktu</b> :
                {{ item.lama_angsuran + ' ' + (item.jenis_upah != null ? item.jenis_upah.replace('AN', '') : '') }}
              </td>
              <td><b>Status</b> : {{ item.status }}</td>
            </tr>
            <tr>
              <td>
                <b>Besar Angsuran</b> :
                {{ new Intl.NumberFormat(['id']).format(parseInt(item.besar_angsuran) + parseInt(item.bunga)) }}
              </td>
              <td>
                <b>X Angsuran</b> :
                {{ item.x_angsur + ' ' + (item.jenis_upah != null ? item.jenis_upah.replace('AN', '') : '') }}
              </td>
              <td>
                <b>Sisa Pinjam</b> :
                {{ new Intl.NumberFormat(['id']).format(item.sisa_pinjaman) }}
              </td>
            </tr>
            <tr>
              <td>
                <b>Tgl Tempo</b> :
                {{ formatDate(item.tgl_tempo) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-data-table dense :headers="headers" :items="desserts" :loading="loading" class="elevation-1">
          <template v-slot:top v-if="item.status == 'BELUM LUNAS'">
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Bayar Angsuran </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Bayar Angsuran</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            readonly
                            label="Angsuran Ke"
                            v-model="editedItem.angsuran_ke"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Besar Angsuran"
                            readonly
                            v-model="editedItem.besar_angsuran"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" :loading="property.save_button" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.no="{ item, index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:item.besar_angsuran="{ item }">
            {{ new Intl.NumberFormat(['id']).format(item.besar_angsuran) }}
          </template>
          <template v-slot:item.denda="{ item }">
            {{ new Intl.NumberFormat(['id']).format(item.denda) }}
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn v-if="user.is_superadmin == true" x-small color="error" @click="deleteItem(item)">Hapus</v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn small color="primary" @click="getDataPinjaman()"> Reload</v-btn>
          </template>
        </v-data-table>

        <!-- dialog delete -->
        <v-layout row justify-center>
          <v-dialog persistent v-model="dialogDelete" max-width="350">
            <v-card>
              <v-card-title class="headline">Konfirmasi</v-card-title>
              <v-card-text>
                <h3 class="mb-4">Apakah yakin akan menghapus?</h3>
                <v-text-field
                  type="password"
                  outlined
                  hide-details=""
                  dense
                  v-model="pin_super"
                  label="Masukkan pin:"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialogDelete = false">Batal</v-btn>
                <v-btn color="error" @click="commitDeleteItem()">Hapus</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      id_item: this.$route.params.id_pinjaman,
      item: {},
      property: {
        save_button: false,
      },

      // datatable
      desserts: [],
      loading: true,
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Tanggal Bayar', sortable: false, value: 'created_at' },
        { text: 'Angsuran Ke', value: 'angsuran_ke', sortable: false },
        { text: 'Besar Angsuran', value: 'besar_angsuran', sortable: false, align: 'right' },
        { text: 'Denda Angsuran', value: 'denda', sortable: false, align: 'right' },
        { text: 'User Entry', value: 'name', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],

      dialog: false,
      editedItem: {
        angsuran_ke: 0,
        besar_angsuran: 0,
        denda: 0,
        id: '',
      },
      defaultItem: {
        // id_jenis_simpanan: { text: '', value: '' },
        // besar_simpanan: 0,
        // tgl_simpanan: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      },
      dialogDelete: false,
      pin_super: '',
    }
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
    user() {
      return this.$store.getters.user
    },
  },
  created() {
    this.getDataPinjaman()
  },
  methods: {
    close() {
      this.dialog = false
    },
    getDataPinjaman() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Pinjaman/getById/${this.id_item}`, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.item = Object.assign({}, response.data.data)
            this.getAngsurans()

            this.editedItem.besar_angsuran = parseInt(this.item.besar_angsuran) + parseInt(this.item.bunga)
            this.editedItem.denda = 0
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },

    getAngsurans() {
      this.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_pinjaman', this.id_item)
      axios
        .post(`${apiRoot}/api/Angsuran/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            if (this.desserts.length > 0) {
              this.editedItem.angsuran_ke = parseInt(response.data.data[0].angsuran_ke) + 1
            } else {
              this.editedItem.angsuran_ke = 1
            }
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },

    save() {
      this.property.save_button = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_pinjaman', this.id_item)
      form.append('angsuran_ke', this.editedItem.angsuran_ke)
      form.append('besar_angsuran', this.editedItem.besar_angsuran)
      form.append('denda', this.editedItem.denda)
      axios
        .post(`${apiRoot}/api/Angsuran/add`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataPinjaman()
            this.dialog = false
          } else {
            alert(response.data.message)
          }
          this.property.save_button = false
        })
        .catch(error => {
          alert(error)
          this.property.save_button = false
        })
    },
    deleteItem(item) {
      this.editedItem.id = item.id
      this.pin_super = ''
      this.dialogDelete = true
    },
    commitDeleteItem() {
      const fmData = new FormData()
      fmData.append('pinjaman_id', this.id_item)
      fmData.append('item_id', this.editedItem.id)
      fmData.append('pin', this.pin_super)
      axiosPostAuth('api/Angsuran/hapusAngsuran', fmData)
        .then(response => {
          if (response.code === 200) {
            alert(response.message)
            this.getDataPinjaman()
            this.dialogDelete = false
          } else {
            alert(response.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
